import {Action, createSelector, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {GetTcCoordinators} from './coordinators.action';
import {CoordinatorsApiService} from '@matchsource/api/coordinators';
import {catchError, tap} from 'rxjs/operators';
import {CoordinatorModel} from '@matchsource/models/coordinator';
import {patch} from '@ngxs/store/operators';
import {LoadingStateModel, PartialEntitiesStateModel} from '@matchsource/store/core';
import {of} from 'rxjs';

export type CoordinatorsStateModel = PartialEntitiesStateModel<CoordinatorModel[]>;

@State<CoordinatorsStateModel>({
  name: 'coordinators',
  defaults: {
    entities: {},
    entityState: {},
  },
})
@Injectable()
export class CoordinatorsState {
  @Selector([CoordinatorsState])
  static entities({entities}: CoordinatorsStateModel): MsApp.Dictionary<CoordinatorModel[]> {
    return entities;
  }

  @Selector([CoordinatorsState])
  static entityState({entityState}: CoordinatorsStateModel): MsApp.Dictionary<LoadingStateModel> {
    return entityState;
  }

  static tcCoordinators(tcId: MsApp.Guid) {
    return createSelector(
      [CoordinatorsState.entities],
      (entities: MsApp.Dictionary<CoordinatorModel[]>) => entities[tcId] ?? []
    );
  }

  static loaded(tcId: MsApp.Guid) {
    return createSelector(
      [CoordinatorsState.entityState],
      (entityState: MsApp.Dictionary<LoadingStateModel>) => entityState[tcId]?.loaded || false
    );
  }

  constructor(private readonly coordinatorsApi: CoordinatorsApiService) {}

  @Action(GetTcCoordinators)
  getTcCoordinators(ctx: StateContext<CoordinatorsStateModel>, {tcId}: GetTcCoordinators) {
    const {entityState} = ctx.getState();
    const tcState = tcId in entityState ? entityState[tcId] : null;

    if (tcState && (tcState.loaded || tcState.loading)) {
      return;
    }

    ctx.setState(
      patch({
        entityState: patch({
          [tcId]: {
            loading: true,
            loaded: false,
            error: null,
          },
        }),
      })
    );

    return this.coordinatorsApi.getTcCoordinators(tcId).pipe(
      catchError(error => {
        ctx.setState(
          patch({
            entityState: patch({
              [tcId]: patch({
                error,
              }),
            }),
          })
        );

        return of([]);
      }),
      tap(coordinators => {
        ctx.setState(
          patch({
            entities: patch({
              [tcId]: coordinators,
            }),
            entityState: patch({
              [tcId]: patch({
                loading: false,
                loaded: true,
              }),
            }),
          })
        );
      })
    );
  }
}
